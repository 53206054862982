var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"topbar"},[_c('div',{class:{'is-active': !_vm.isUserMenuHidden},attrs:{"id":"c-mask-user-menu"},on:{"click":function($event){return _vm.toggleUserMenu()}}}),_c('nav',{staticClass:"nav"},[_c('div',{staticClass:"nav-left"},[(!_vm.isCurrentUserClient)?_c('a',{staticClass:"nav-item sidebar-button",class:{'selected': !_vm.isSidebarHidden},attrs:{"id":"toggle-menu-button"},on:{"click":function($event){return _vm.toggleSidebar()}}},[_vm._v(" ≡ ")]):_vm._e(),(_vm.isProductionContext)?_c('div',{staticClass:"flexrow topbar-menu"},[_c('div',{staticClass:"flexrow-item subitem"},[_c('topbar-production-list',{attrs:{"episode-id":_vm.currentEpisodeId,"production-list":_vm.openProductions,"production":_vm.currentProduction,"section":_vm.currentSectionOption}})],1),_c('div',{staticClass:"flexrow-item"},[_c('chevron-right-icon',{staticClass:"mt05",attrs:{"size":"1.4x"}})],1),_c('div',{staticClass:"flexrow-item subitem"},[_c('topbar-section-list',{attrs:{"episode-id":_vm.currentEpisodeId,"section-list":_vm.sectionOptions,"section":_vm.currentSectionOption}})],1),(_vm.isEpisodeContext)?_c('div',{staticClass:"flexrow-item"},[_c('chevron-right-icon',{staticClass:"mt05",attrs:{"size":"1.4x"}})],1):_vm._e(),_c('div',{staticClass:"flexrow-item subitem"},[(_vm.isEpisodeContext)?_c('topbar-episode-list',{attrs:{"episode-list":_vm.currentEpisodeOptions,"episode-id":_vm.currentEpisodeId,"section":_vm.currentSectionOption}}):_vm._e()],1)]):(_vm.lastProduction && _vm.$route.path !== '/open-productions')?_c('div',{staticClass:"nav-item"},[_c('router-link',{staticClass:"flexrow",attrs:{"to":_vm.lastSectionPath}},[_c('chevron-left-icon'),_vm._v(" "+_vm._s(_vm.$t('main.go_productions'))+" ")],1)],1):_vm._e()]),_c('div',{staticClass:"nav-right"},[(!_vm.isCurrentUserAdmin)?_c('router-link',{staticClass:"nav-item",attrs:{"to":{
          name: 'todos-tab',
          params: { tab: 'todos' }
        }}},[_vm._v(" "+_vm._s(_vm.$t('tasks.my_tasks'))+" ")]):_vm._e(),(!_vm.isCurrentUserAdmin)?_c('router-link',{staticClass:"nav-item",attrs:{"to":{
          name: 'todos-tab',
          params: { tab: 'timesheets' }
        }}},[_vm._v(" "+_vm._s(_vm.$t('timesheets.title'))+" ")]):_vm._e(),_c('div',{staticClass:"nav-item"},[_c('button',{staticClass:"changelog-button",attrs:{"data-canny-changelog":""}},[_c('zap-icon')],1)]),_c('notification-bell'),_c('div',{ref:"user-name",class:{
          'nav-item': true,
          'user-nav': true,
          active: !_vm.isUserMenuHidden
        },on:{"click":_vm.toggleUserMenu}},[_c('people-avatar',{ref:"avatar",staticClass:"avatar",attrs:{"no-cache":true,"person":_vm.user,"is-link":false}})],1)],1)]),_c('nav',{ref:"user-menu",staticClass:"user-menu",style:({
      top: _vm.isUserMenuHidden ? '-460px' : '60px'
    })},[_c('ul',[_c('li',[_c('router-link',{attrs:{"to":"/profile"},nativeOn:{"click":function($event){return _vm.toggleUserMenu()}}},[_vm._v(" "+_vm._s(_vm.$t("main.profile"))+" ")])],1),_c('li',{on:{"click":_vm.toggleDarkTheme}},[(!_vm.isDarkTheme)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("main.dark_theme"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("main.white_theme"))+" ")])]),_c('hr'),_c('li',[_c('a',{attrs:{"href":"https://kitsu.cg-wire.com","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("main.documentation"))+" ")])]),_c('li',[_c('a',{attrs:{"href":"https://www.youtube.com/playlist?list=PLp_1gB5ZBHXqnQgZ4TCrAt7smxesaDo29","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('main.tutorials'))+" ")])]),_c('li',[_c('a',{on:{"click":function($event){_vm.display.shortcutModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('keyboard.shortcuts'))+" ")])]),_c('hr'),_vm._m(0),_vm._m(1),_c('hr'),_c('li',[_c('a',{attrs:{"href":"https://cg-wire.com/en/about.html","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("main.about"))+" ")])]),_c('li',{staticClass:"version"},[_vm._v(" Kitsu "+_vm._s(_vm.kitsuVersion)+" ")]),_c('hr'),_c('li',{staticClass:"flexrow",on:{"click":_vm.onLogoutClicked}},[_c('log-out-icon',{staticClass:"flexrow-item",attrs:{"size":"1x"}}),_c('span',{staticClass:"flexrow-item"},[_vm._v(_vm._s(_vm.$t("main.logout")))])],1)])]),_c('shortcut-modal',{attrs:{"active":_vm.display.shortcutModal},on:{"cancel":function($event){_vm.display.shortcutModal = false}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"https://discord.gg/VbCxtKN","target":"_blank"}},[_vm._v(" Discord ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"https://cgwire.canny.io","target":"_blank"}},[_vm._v(" Roadmap / Feedback ")])])}]

export { render, staticRenderFns }