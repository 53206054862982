import { render, staticRenderFns } from "./NewProduction.vue?vue&type=template&id=60b53ec7&scoped=true&"
import script from "./NewProduction.vue?vue&type=script&lang=js&"
export * from "./NewProduction.vue?vue&type=script&lang=js&"
import style0 from "./NewProduction.vue?vue&type=style&index=0&id=60b53ec7&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60b53ec7",
  null
  
)

export default component.exports